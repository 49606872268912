/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}


html {
  min-height: 100%;
  height: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/**
 * Basic styling
 */
body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    min-height: 100%;
    display: flex;
    display: -webkit-flex; /* Safari */
    -webkit-flex-flow: column; /* Safari 6.1+ */
    flex-flow: column;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: darken($brand-color, 15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {

    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));

    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {

        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));

        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}

/**
 * Wrapper (for full width)
 */
.wrapper-full {

    max-width: -webkit-calc(#{$content-width-wide} - (#{$spacing-unit}));
    max-width:         calc(#{$content-width-wide} - (#{$spacing-unit}));

    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {

        max-width: -webkit-calc(#{$content-width-wide} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width-wide} - (#{$spacing-unit}));

        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}



/**
 * Custom stuff
 */
table {
  text-align: left;
  border-collapse: collapse
}

th, td {
  padding: 0em 1em;

}


.avatar {
    float: right;
    max-width: 30%;
    margin-left: 2em;
    margin-bottom: 1em;
    box-shadow: 5px 5px 5px $grey-color-dark;
}

.banner-image {
   filter: drop-shadow(5px 5px 5px $grey-color-dark);
   -webkit-filter: drop-shadow(5px 5px 5px $grey-color-dark);
   margin-bottom: 1em;
   z-index: -100;
 }


.hide-on-mobile {
  @include media-query(1000px) {
    display: none;
  }
}

#search-query {
  font-size: 100%;
  width: 100%;
  padding: 0.5em;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

#search, #search-results-wrapper {
  padding: 10px 30px;
}

.entries {
  border-top: 1px solid $grey-color-light;
  padding-top: 25px;
}

.entries article {
  padding-bottom: 10px;
  clear: both;
}

.entries h1 {
  margin-bottom: 0px;
  font-size: 130%;
}

.excerpt {
  font-size: 100%;
}

.search-image {
    float: left;
    position: relative;
    margin-right: 2em;
    box-shadow: 5px 5px 5px $grey-color-dark;
    margin-bottom: 25px;
}

small {
    font-size: $small-font-size;
}

em, .em {
  font-weight: 600;
}

table.contact th {
  text-align: right;
  padding-right: 0;
  padding-bottom: 0.5em;
}

